<template>
  <div class="container-actividad">
    <form class="ion-padding" @submit.prevent="submitForm">
      <ion-list>
        <ion-item lines="inset">
          <ion-label position="floating"
            >Dia <span class="asterisk">*</span></ion-label
          >
          <ion-input
            type="text"
            placeholder="Dia"
            v-model="dataActividad.dia"
            inputmode="text"
          />
        </ion-item>
        <ion-item lines="inset">
          <ion-label position="floating"
            >Fecha Inicio <span class="asterisk">*</span></ion-label
          >
          <ion-datetime
            v-model="fechaInicio"
            min="2021"
            max="2025"
            display-format="DDDD DD/MM/YYYY"
            :day-names="dayNames"
            cancel-text="Cancelar"
            done-text="Hecho"
            placeholder="Seleccionar fecha"
          ></ion-datetime>
        </ion-item>
        <ion-item lines="inset">
          <ion-label position="floating">Fecha Final</ion-label>
          <ion-datetime
            v-model="fechaFinal"
            min="2021"
            max="2025"
            display-format="DDDD DD/MM/YYYY"
            :day-names="dayNames"
            cancel-text="Cancelar"
            done-text="Hecho"
            placeholder="Seleccionar fecha"
          ></ion-datetime>
        </ion-item>
        <item-materiales-component
          v-if="materiales.length > 0"
        ></item-materiales-component>
        <ion-item lines="inset">
          <ion-button type="button" fill="clear" @click="showMateriales">
            <ion-icon slot="start" :icon="cubeOutline"></ion-icon>
            <ion-label>Materiales</ion-label>
          </ion-button>
        </ion-item>
        <item-material-new v-if="addMaterial"></item-material-new>
        <item-equipos-component
          v-if="equipos.length > 0"
        ></item-equipos-component>
        <ion-item lines="inset">
          <ion-button type="button" fill="clear" @click="showEquipos">
            <ion-icon slot="start" :icon="constructOutline"></ion-icon>
            <ion-label>Equipos</ion-label>
          </ion-button>
        </ion-item>
        <item-equipo-new v-if="addEquipo"></item-equipo-new>
        <personal-select></personal-select>
        <div class="thumbnail" v-if="dataActividad.images.length > 0">
          <div
            class="soporte"
            slot="start"
            v-for="image in dataActividad.images"
            :key="image"
          >
            <img :src="image" alt="" />
            <ion-button class="quit-img" fill="clear" @click="quitImage(image)"
              ><ion-icon slot="icon-only" :icon="trashOutline"></ion-icon
            ></ion-button>
          </div>
        </div>
        <ion-item lines="inset">
          <ion-button type="button" fill="clear" @click="getEvidencia">
            <ion-icon slot="start" :icon="camera"></ion-icon>
            <ion-label>Agregar Imagenes</ion-label>
            <ion-input
              type="file"
              @ionChange="onFileSelected"
              hidden
              v-model="dataActividad.images"
              multiple
            ></ion-input>
          </ion-button>
        </ion-item>
        <ion-item lines="inset">
          <ion-label position="floating"
            >Nombre de quien recibe <span class="asterisk">*</span></ion-label
          >
          <ion-input
            type="text"
            placeholder="Nombre de quien recibe"
            v-model="dataActividad.responsable"
            inputmode="text"
          />
        </ion-item>
        <ion-item lines="inset">
          <ion-label position="floating"
            >Documento de quien recibe <span class="asterisk">*</span></ion-label
          >
          <ion-input
            type="text"
            placeholder="Documento"
            v-model="dataActividad.documento"
            inputmode="numeric"
          />
        </ion-item>
        <ion-item lines="inset">
          <ion-label position="floating"
            >Cargo de quien recibe <span class="asterisk">*</span></ion-label
          >
          <ion-input
            type="text"
            placeholder="Cargo"
            v-model="dataActividad.cargo"
            inputmode="text"
          />
        </ion-item>
        <ion-item lines="inset">
          <ion-label position="floating">Observaciones</ion-label>
          <ion-textarea
            rows="3"
            v-model="dataActividad.observaciones"
            inputmode="text"
          ></ion-textarea>
        </ion-item>
        <div lines="none" class="item-firmas">         
            <ion-label class="label-firmas">
            Firma Autorizada <span class="asterisk">*</span>
          </ion-label>          
            <item-canvas-component :firma="1"></item-canvas-component>          
        </div>
         <div lines="none" class="item-firmas">
          <ion-label class="label-firmas">
            Firma Ingeniaría Beling <span class="asterisk">*</span>
          </ion-label>          
            <item-canvas-component :firma="2"></item-canvas-component>                 
        </div>
      </ion-list>
      <ion-item lines="none">
        <p>
          <ion-text
            >Los campos indicados con <span class="asterisk">*</span> son
            obligatorios</ion-text
          >
        </p>
      </ion-item>
      <ion-button type="submit" expand="block" fill="outline"
        >Guardar</ion-button
      >
      <ion-button type="button" @click="cancel" expand="block" fill="outline"
        >Cancelar</ion-button
      >
    </form>
  </div>
</template>
<script>
import {
  IonItem,
  IonList,
  IonInput,
  IonLabel,
  IonButton,
  IonDatetime,
  IonIcon,
  toastController,
  loadingController,
  IonText,
  IonTextarea,
} from "@ionic/vue";
import {
  camera,
  trashOutline,
  cubeOutline,
  constructOutline,
} from "ionicons/icons";
import { Capacitor } from "@capacitor/core";
import { Camera, CameraDirection, CameraResultType, CameraSource } from "@capacitor/camera";
import { mapGetters, mapActions } from "vuex";
import ItemMaterialNew from "./ItemMaterialNew.vue";
import ItemMaterialesComponent from "./ItemMaterialesComponent.vue";
import ItemEquipoNew from "./ItemEquipoNew.vue";
import ItemEquiposComponent from "./ItemEquiposComponent.vue";
import ItemCanvasComponent from "./ItemCanvasComponent.vue";
import PersonalSelect from '../common/PersonalSelect.vue';
export default {
  name: "create-actividad-form",
  components: {
    IonItem,
    IonList,
    IonInput,
    IonLabel,
    IonButton,
    IonDatetime,
    IonIcon,
    ItemMaterialNew,
    ItemMaterialesComponent,
    ItemEquipoNew,
    ItemEquiposComponent,
    ItemCanvasComponent,
    IonText,
    IonTextarea,
    PersonalSelect,
  },
  setup() {
    const dayNames = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];

    return {
      dayNames,
    };
  },
  data() {
    return {
      personas:[],
      dataActividad: {
        itemId: 0,
        dia: null,
        fechaInicio: null,
        fechaFinal: null,
        materiales: [],
        equipo: [],
        personal: [],
        images: [],
        responsable: null,
        documento: null,
        cargo: null,
        proceso: null,
        observaciones: null,
        firmaCliente: null,
        firmaIngenieria: null,
      },
      dataDefault: {
        itemId: 0,
        actividad: null,
        dia: null,
        fechaInicio: null,
        fechaFinal: null,
        materiales: [],
        equipo: [],
        personal: [],
        images: [],
        responsable: null,
        documento: null,
        cargo: null,
        proceso: null,
        observaciones: null,
        firmaCliente: null,
        firmaIngenieria: null,
      },
      fechaInicio: null,
      fechaFinal: null,
      camera,
      trashOutline,
      cubeOutline,
      constructOutline,
      timeout: 1000,
      canvas: false,
      file: null,
      autorizar: false,
      addMaterial: false,
      addEquipo: false,
      toatsMessage: "",
      toatsColor: "",
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("item", [
      "empleados",
      "itemId",
      "materiales",
      "equipos",
      "equipo",
      "personal",
      "material",
      "firmaAutorizada",
      "firmaIngenieria",
    ]),
  },
  mounted() {
    if (process.env.MODE == "capacitor") {
      const { PushNotifications } = require("@capacitor/local-notifications");
      console.log(Object.keys(PushNotifications));
      PushNotifications.requestPermission().then((result) => {
        if (result.granted) {
          PushNotifications.register();
        }
      });
    }
    this.retrievePersonal();    
  },
  methods: {
    ...mapActions("item", [
      "addActividad",
      "clearMaterial",
      "clearEquipo",
      "setFirmaAutorizada",
      "setFirmaIngenieria",
      "retrievePersonal",
      "retrieveMateriales",
      "retrieveEquipos",
    ]),

    async submitForm() {
      
      this.dataActividad.personal = this.empleados;      
      this.dataActividad.itemId = this.itemId.id;
      this.dataActividad.proceso = this.user.name;
      this.dataActividad.actividad = this.itemId.name;
      this.dataActividad.materiales = this.materiales;
      this.dataActividad.equipo = this.equipos;
      this.dataActividad.fechaInicio = await this.timeConverter(
        this.fechaInicio
      );
      this.dataActividad.fechaFinal = await this.timeConverter(this.fechaFinal);
      this.dataActividad.firmaCliente = this.firmaAutorizada;
      this.dataActividad.firmaIngenieria = this.firmaIngenieria;
      if (this.dataActividad.itemId === 0) {
        this.toatsMessage = "No hay un item para guardar actividad";
        this.toatsColor = "warning";
        this.openToast();
      } else if (
        this.dataActividad.dia === null ||
        this.dataActividad.firmaIngenieria === null ||
        this.dataActividad.firmaCliente === null ||
        this.dataActividad.fechaInicio === "1969-12-31 19:0:0"
      ) {
        this.toatsMessage = "Verifique los campos obligatorios";
        this.toatsColor = "warning";
        this.openToast();
      } else {
        this.timeout = 100000;
        const loading = await loadingController.create({
          message: "Enviando información...",
          duration: this.timeout,
        });
        await loading.present();
        const result = await this.addActividad({
          actividad: this.dataActividad,
        });
        if (result.status === 201) {
          this.toatsMessage = "Actividad agregada exitosamente";
          this.toatsColor = "light";
          this.openToast();
          this.cleanForm();
        } else if (result.status === 500) {
          this.toatsColor = "danger";
          this.toatsMessage = `¡Error interno del servidor!.<br>Contacte al administrador`;
          this.openToast();
        } else if (result.status === 422) {
          let errorMessate = "";
          const errors = await JSON.parse(JSON.stringify(result.data.errors));
          for (const i in errors) {
            errorMessate += `${errors[i][0]}\n`;
          }
          this.toatsColor = "danger";
          this.toatsMessage = `No se ha enviado toda la información<br>¡Erores!<br>${errorMessate}`;
          await this.openToast();
        } else {
          this.toatsColor = "danger";
          this.toatsMessage = "Error. No se ha realizado el registro";
          this.openToast();
        }
        loading.dismiss();
      }
    },
    async cancel() {
      await this.cleanForm();
    },
    async cleanForm() {
      let image = null;
      //let points = [];
      //localStorage["points"] = JSON.stringify(points);
      localStorage.removeItem("points");
      this.dataActividad = this.dataDefault;
      this.fechaInicio = null;
      this.fechaFinal = null;
      this.addMaterial = false;
      this.addEquipo = false;
      await this.clearMaterial();
      await this.clearEquipo();
      await this.setFirmaAutorizada(image);
      await this.setFirmaIngenieria(image);
      this.$router.replace("/items/Items");
    },
    onFileSelected(event) {
      this.dataActividad.images = event.target.value;
    },
    firmaClienteSelected(event) {
      this.dataActividad.firmaCliente = event.target.value;
    },
    firmaIngenieriaSelected(event) {
      this.dataActividad.firmaIngenieria = event.target.value;
    },
    async openToast() {
      const toast = await toastController.create({
        message: this.toatsMessage,
        position: "middle",
        color: this.toatsColor,
        duration: 3000,
      });
      return toast.present();
    },
    async takePhoto() {
      let image = null;
      const isAvailable = Capacitor.isPluginAvailable("Camera");
      if (!isAvailable) {
        console.log(isAvailable);
      } else {
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.DataUrl,
          allowEditing: true,
          source: CameraSource.Prompt,
          quality: 60,
          direction:CameraDirection.Rear,
        }).catch(() => {
          this.toatsColor = "warning";
          this.toatsMessage = `¡Debes enviar una imagen`;
          this.openToast();
        });

        if (photo) {
          image = photo && photo.dataUrl;
        }
        return image;
      }
    },
    async getEvidencia() {
      const photo = await this.takePhoto();
      this.dataActividad.images.push(photo);
    },
    async timeConverter(timestamp) {
      const a = new Date(timestamp);
      const year = a.getFullYear();
      const month = a.getMonth() + 1;
      const date = a.getDate();
      const hour = a.getHours();
      const min = a.getMinutes();
      const sec = a.getSeconds();
      const time = `${year}-${month}-${date} ${hour}:${min}:${sec}`;
      return time;
    },
    quitImage(image) {
      let index = this.dataActividad.images.find((img) => img == image);
      this.dataActividad.images.splice(
        this.dataActividad.images.indexOf(index),
        1
      );
    },
    showMateriales() {
      this.addMaterial = this.addMaterial === false ? true : false;
    },
    showEquipos() {
      this.addEquipo = this.addEquipo === false ? true : false;
    },
  },
};
</script>

<style scoped>
.container-actividad {
  margin: auto;
}
.thumbnail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 0.1em solid #ddd;
  margin: 0.2em;
  margin-left: 0.7em;
  padding: 0.1em;
  justify-content: space-between;
}
.soporte {
  height: 100px;
  width: 100px;
  margin: 0.1em;
  margin-bottom: 10%;
}
.soporte img {
  height: 100%;
  width: 100%;
  object-fit: cover !important;
}
.quit-img {
  margin-top: -10%;
}
.asterisk {
  color: red;
}
.my-1{
  margin: 5px 0 5px 0;
}
ion-text {
  font-size: 12px;
}
.item-firmas{
  display: flex;
  flex-direction: column;
}
.label-firmas{
  text-align: left;
  margin-left: 1rem;
  margin-top: 1rem;
}
</style>
