<template>
  <div class="canvas">
    <canvas ref="canvas" id="drawing-pad" width="290" height="200">
      Firma Autorizada
    </canvas>
    <div>
      <ion-button type="button" fill="clear" @click="resetFirma">
        <ion-icon slot="start" :icon="reloadOutline"></ion-icon>
        <ion-label>Reset</ion-label>
      </ion-button>
      <ion-button type="button" fill="clear" @click="saveFirma">
        <ion-icon slot="start" :icon="saveOutline"></ion-icon>
        <ion-label>Guardar</ion-label>
      </ion-button>
      <ion-button type="button" fill="clear" @click="replay">
        <ion-icon slot="start" :icon="eyeOutline"></ion-icon>
        <ion-label>Replay</ion-label>
      </ion-button>
    </div>
  </div>
</template>
<script>
import { IonButton, IonIcon, IonLabel } from "@ionic/vue";
import { mapActions } from "vuex";
import { reloadOutline, saveOutline, eyeOutline } from "ionicons/icons";
export default {
  name: "item-canvas-component",
  components: {
    IonButton,
    IonIcon,
    IonLabel,
  },
  props: ["firma"],
  data() {
    return {
      canvas: null,
      context: null,
      isDrawing: false,
      startX: 0,
      startY: 0,
      points: [],
      reloadOutline,
      saveOutline,
      eyeOutline,
    };
  },
  mounted() {
    let vm = this;
    vm.canvas = vm.$refs.canvas;
    vm.context = vm.canvas.getContext("2d");
    vm.canvas.addEventListener("mousedown", vm.mousedown);
    vm.canvas.addEventListener("mousemove", vm.mousemove);
    document.addEventListener("mouseup", vm.mouseup);
    vm.canvas.addEventListener("touchstart", vm.touchstart);
    vm.canvas.addEventListener("touchmove", vm.touchmove);
    vm.canvas.addEventListener("touchend", vm.touchend);
  },
  methods: {
    ...mapActions("item", ["setFirmaAutorizada", "setFirmaIngenieria"]),
    mousedown(e) {
      let vm = this;
      let rect = vm.canvas.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;

      vm.isDrawing = true;
      vm.startX = x;
      vm.startY = y;
      vm.points.push({
        x: x,
        y: y,
      });
    },
    mousemove(e) {
      let vm = this;
      let rect = vm.canvas.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;

      if (vm.isDrawing) {
        vm.context.beginPath();
        vm.context.moveTo(vm.startX, vm.startY);
        vm.context.lineTo(x, y);
        vm.context.lineWidth = 1;
        vm.context.lineCap = "round";
        vm.context.strokeStyle = "rgba(0,0,0,1)";
        vm.context.stroke();

        vm.startX = x;
        vm.startY = y;

        vm.points.push({
          x: x,
          y: y,
        });
      }
    },
    mouseup() {
      let vm = this;
      vm.isDrawing = false;

      if (vm.points.length > 0) {
        localStorage["points"] = JSON.stringify(vm.points);
      }
    },
    touchstart(e) {
      let vm = this;
      let rect = vm.canvas.getBoundingClientRect();
      let x = e.touches[0].pageX - rect.left;
      let y = e.touches[0].pageY - rect.top;

      vm.isDrawing = true;
      vm.startX = x;
      vm.startY = y;
      vm.points.push({
        x: x,
        y: y,
      });
    },
    touchmove(e) {
      let vm = this;
      let rect = vm.canvas.getBoundingClientRect();
      let x = e.touches[0].pageX - rect.left;
      let y = e.touches[0].pageY - rect.top;

      if (vm.isDrawing) {
        vm.context.beginPath();
        vm.context.moveTo(vm.startX, vm.startY);
        vm.context.lineTo(x, y);
        vm.context.lineWidth = 1;
        vm.context.lineCap = "round";
        vm.context.strokeStyle = "rgba(0,0,0,1)";
        vm.context.stroke();

        vm.startX = x;
        vm.startY = y;

        vm.points.push({
          x: x,
          y: y,
        });
      }
    },
    touchend() {
      let vm = this;
      vm.isDrawing = false;

      if (vm.points.length > 0) {
        localStorage["points"] = JSON.stringify(vm.points);
      }
    },
    resetFirma() {
      let vm = this;
      vm.canvas.width = 290;
      vm.points.length = 0;
      localStorage["points"] = JSON.stringify(vm.points);
    },
    saveFirma() {
      let vm = this;
      let dataURL = vm.canvas.toDataURL(); /* 
      let img = vm.$refs.img;
      img.src = dataURL; */
      if (vm.firma === 1) {
        vm.setFirmaAutorizada(dataURL);
      } else if (vm.firma === 2) {
        vm.setFirmaIngenieria(dataURL);
      }
    },
    replay() {
      let vm = this;
      vm.canvas.width = 290;

      if (vm.points.length === 0) {
        if (localStorage["points"] !== undefined) {
          vm.points = JSON.parse(localStorage["points"]);
        }
      }

      let point = 1;
      setInterval(() => {
        drawNextPoint(point);
        point += 1;
      }, 10);

      function drawNextPoint(index) {
        if (index >= vm.points.length) {
          return;
        }

        let startX = vm.points[index - 1].x;
        let startY = vm.points[index - 1].y;

        let x = vm.points[index].x;
        let y = vm.points[index].y;

        vm.context.beginPath();
        vm.context.moveTo(startX, startY);
        vm.context.lineTo(x, y);
        vm.context.lineWidth = 1;
        vm.context.lineCap = "round";
        vm.context.strokeStyle = "rgba(0,0,0,1)";
        vm.context.stroke();
      }
    },
  },
};
</script>

<style scoped>
canvas {
  border: 0.1em solid #aaa;
  cursor: crosshair;
}
.canvas {
  margin-top: 1em;
  margin-right: 1em;
}
</style>