<template>
  <base-layout>
    <create-actividad-form></create-actividad-form>
  </base-layout>
</template>

<script>
import CreateActividadForm from '../components/item/CreateActividadForm.vue'
export default {
    name: 'add-actividad-page',
  components: { CreateActividadForm },

}
</script>
<style scoped>
#container {
  text-align: center;
  position: relative;
  left: 0;
  right: 0;
}
</style>
